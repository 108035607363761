:root{
    --mainOpacity: rgba(0, 0, 0, 0.55);
}

/* .header {
    background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url("./Images/PublicImages/image-2.jpg");
    opacity: var(--mainOpacity);
    height: 25em !important;
} */

.dark-overlay {
    background-color: rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.main-color {
background-color: #0e76a8 ;
}

.col-image-left {
    background-image: url("./Images/PublicImages/image-4.jpg");
    height: 25em !important;
}

.col-image-right {
    background-image: url("./Images/PublicImages/image-1.jpg");
    height: 25em !important;
}

.lost-image {
    background-image: url("./Images/PublicImages/image-3.jpg");
    height: 25em !important;
    width: "720"
}

.homepage-carousel-title {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

.resource-div {
    width: 480px;
    height: 384px;
}

.inactiveLink {
    pointer-events: none;
    cursor: default;
 }

 .selectoption {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.ctoc{
    padding: 0;
    border: none;
    background: none;
}

.dnowrap {
    white-space: nowrap;
    /* overflow: hidden; */
  }

  a {word-break: break-all;}

.btnctoc{
    padding: 0;
    border: none;
    background-color: rgb(255, 255, 255);
    
}

.btnctoc:active{
    padding: 0;
    border: none;
    background-color: rgb(0, 0, 0);
        
}

.purple-background{
    background-image: url(https://mindfuel.ca/wp-content/uploads/2022/11/lp11-bg.png), linear-gradient(180deg,#b32ce0 0%,#4822a3 100%)!important;
}

.text-title-purple {
    color: #532380;
    font-family: 'Barlow',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.3em;
}

.text-basic {
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 300;
}

.button-orange {
    background-color: #f7901e;
}

.text-purple {
    color: #532380;
    font-family: chevin-pro,sans-serif;
}

.text-orange {
    color: #f7901e;
}

.rounded-corner {
    border-radius: 1rem;
}

.fillRemaining {
    height: calc(100vh - 200px);
}

.purple-color {
    background-color: #532380!important;
}

.text-justify {
    text-align:justify !important;
}